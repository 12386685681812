import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StyledModal, StyledBody, Main, ImgDiv, FeedDiv,
  //  CloseDiv
  NoComments, CloseImgDiv } from './styles';
import { ImageUrl } from '../../utils/constants';
import isEmpty from 'lodash/isEmpty';
import PhotoComments from '../CommentsV3/photoComments';
import { isNull, isUndefined } from 'lodash';
import Waiting from '../Waiting';
import { addCommentReplyOnPost, getCommentsOnPost } from '../../redux/actions';
import {connect} from 'react-redux';
import SinglePhotoForComment from '../MyPhotos/SinglePhotoForComment';
import { getInspirationPost } from '../../redux/actions/socialActions';

class ViewPhotoCommentModal extends Component{


  componentDidMount(){
    this.props.fetchCommentsOnPost(this.props.photo.id);
    this.props.getInspirationPost(this.props.photo.id);
  }
  render(){
    const { showModal, onClose, photo, comments } = this.props;
    if(isUndefined(photo) || isNull(photo)){
      return <Waiting/>
    }
    return(
      <div style={{width:'100%'}}>
        <StyledModal show={showModal}>
          <StyledBody>
            <Main width={photo.image? true : false}>
              <div className={'bodyContainer'}>
                <ImgDiv width={photo.image? true : false}>
                  <CloseImgDiv onClick={onClose}>
                    <img src="/public/images/social/closeCommentIcon.png" alt="closeIcon" />
                  </CloseImgDiv>
                  <div>
                    {
                      photo.image ? <img src={`${ImageUrl}/${photo.image}`} /> : null
                    }
                  </div>
                </ImgDiv>
                <FeedDiv width={photo.image? true : false}>
                  <SinglePhotoForComment
                    photo={photo}
                    description={photo.description}                   
                    hideCommentText = {false}
                    exemplifiesValues={this.props.exemplifiesValues}
                    getCommentsForPost={this.props.getCommentsForPost}
                    profileImage={this.props.profileImage}
                    enableTrigger={this.props.enableTrigger}
                    handleModal={this.props.handleModal}
                    handleFeed={this.props.handleFeed}
                    feedNumber={this.props.feedNumber}
                    data={this.props.data}
                    taggedUsers={photo.shoutout_user_name}
                    updationList="userPostImages"
                    updateFeedsLike={this.props.updateFeedsLike}
                    inspirationPost={this.props.inspirationPost}
                    userPosts={this.props.userPosts}
                    onChange={this.props.onChange}
                  />
                  {
                    isEmpty(comments) ? <NoComments>No Comments to display</NoComments> :
                      <PhotoComments
                        comments={comments}
                        postReply={this.props.postReplyOnComment}
                        replySuccess={this.props.replySuccess}
                        id={photo.id}
                        history={this.props.history}
                        photo={photo}
                        enableTrigger={this.props.enableTrigger}
                        onChange={this.props.onChange}
                      />
                  }
                </FeedDiv>
              </div>
            </Main>
          </StyledBody>
        </StyledModal>
      </div>
    )
  }
}



ViewPhotoCommentModal.propTypes =  {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  feed: PropTypes.object.isRequired,
  description: PropTypes.string,
  taggedUsers: PropTypes.string,
  getCommentsForPost: PropTypes.func.isRequired,
  changeEditDeletePopup: PropTypes.func.isRequired,
  editAndDelete: PropTypes.bool.isRequired,
  openEditAndDelete: PropTypes.func.isRequired,
  profileImage: PropTypes.string,
  updationList: PropTypes.string,
  commentCount: PropTypes.number,
  history: PropTypes.object,
  enableTrigger: PropTypes.bool,
  onChange: PropTypes.func,
  handleFeed: PropTypes.func,
  handleModal: PropTypes.func.isRequired,
  feedNumber : PropTypes.number,
  data: PropTypes.array.isRequired,
  exemplifiesValues : PropTypes.array,
  showRecognition : PropTypes.func,
  value : PropTypes.string,
  //id : PropTypes.number,
  image : PropTypes.string,
  feedNo : PropTypes.number,
  updateFeedsLike: PropTypes.func,
  editPost: PropTypes.func,
  storedFeeds: PropTypes.array,
  socialCallBack: PropTypes.func,
  handlePhotoPostModal: PropTypes.func,
  postReplyOnComment: PropTypes.func.isRequired,
  comments: PropTypes.array,
  postReply: PropTypes.func,
  replySuccess: PropTypes.string,
  photo: PropTypes.object.isRequired,
  inspirationPost: PropTypes.array,
  fetchCommentsOnPost: PropTypes.func,
  getInspirationPost: PropTypes.func,
  userPosts: PropTypes.array
};

const mapStateToProps = (state) => ({
  comments: state.social.comments,
  replySuccess: state.social.replySuccess,
  inspirationPost: state.social.inspirationPost
  
});

const mapDispatchToProps = (dispatch) => ({
  fetchCommentsOnPost: (id) => dispatch(getCommentsOnPost(id)),
  postReplyOnComment: (commentId, reply, challengeId, shoutout, shoutoutUserName) => dispatch(addCommentReplyOnPost(commentId, reply, challengeId, shoutout, shoutoutUserName)),
  getInspirationPost: (id) => dispatch(getInspirationPost(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPhotoCommentModal);
