import styled from 'styled-components';
import {Modal} from "react-bootstrap";
const BoldFont = 'Rubik-Medium';

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    ${'' /* width: 1920px;
    height: 1053px; */}
    border-radius: 6px;
    background: linear-gradient(180deg, #FFFFFF 0%,rgba(255,255,255,0) 100%), #FFFFFF;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    transform: translate(0, 50vh) translate(0, -50%) !important;
    margin: 0 auto;
    .modal-content {
      width:100%;
      height:100%;
      background-color: ${({bgcolor}) => (bgcolor || !bgcolor) ? 'transparent' : '#fff'};
    }
    .close {
      margin-top: -30px;
    }
    ${'' /* @media (max-height: 1200px) {
      width: 55%;
    } */}
    @media (max-height: 530px) {
      transform: none !important;
      ${'' /* margin: 30px auto; */}
    }
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
`;

const StyledBody = styled(Modal.Body)`
height: 100%;
width: 100%;
padding: 0;
`;

const Main = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  >div.bodyContainer {
    width: 100%
  }
  >div{
    padding: ${({width}) => width ? `0` : `0`};
    background: ${({width}) => width ? `none` : `black`};
    display:${({width}) => width ? `flex` : `flex`}
  }
`

const ImgDiv = styled.div`
  display: ${({width}) => width ? `block` : `block`};
  width: ${({width}) => width ? `65%` : `65%`};
  position: relative;
  padding: 70px;
  background: black;
  >div:last-child{
    height: 100%;
    width:100%;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    >img{
      height: auto;
      max-height: 100vh;
      max-width: 100%;
    }
  }
`;

const FeedDiv = styled.div`
  width: ${({width, widthValue}) => widthValue?widthValue: width ? `35%` : `35%`};
  // overflow-y: scroll;
  background: white;
  height: 100%;
  height:650px;
  border-radius: ${({radius}) => radius ? "6px" : "0px 6px 6px 0"};
`

const CloseDiv = styled.div`
  float: right;
  cursor: pointer;
    >img{
      height: 25px;
    }
`

const CloseImgDiv = styled.div`
  position: absolute;
  top:10px;
  left:10px;
  cursor: pointer;
    >img{
      height: 40px;
      width: 40px
    }

`

const NoComments = styled.div`
float: left;
width: 100%;
padding: 30px;
font-size: 15px;
font-family: ${BoldFont};
`

const StyledPopup = styled(Modal)`
// width: 1080px;
@media (min-width: 576px){
.modal-dialog {
  max-width:100% !important;
}}
.closeIcon{
  width:100%;
  display:flex;
  margin:auto;
  cursor:pointer;
  >img{
    width:22px;
    height:22px;
    margin:auto;
    margin-right:10px;
    margin-bottom:10px;
  }
}
.modal-backdrop.in{
  opacity: 0 !important;
}
.modal-dialog {
  width: ${({width}) => width ? width : `1080px !important`};
  border-radius: 8px;
  background: transparent;
  // box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  transform: translate(0, 50vh) translate(0, -50%);
  margin: 80px auto;
  border: none;
  @media (min-width: 576px){
.modal-dialog {
  max-width:100% !important;
}}
  @media (max-width: 576px) {
    max-width: 100%;
  }
  .modal-backdrop.in{
    opacity: 0.1;
  }
  .modal-backdrop{
    background:trasparent;
  }
  .modal-content {
    height: 100%;
    width:100%;
    width: ${({width}) => width ? width : `1080px`};
    border: none;
    background-color: ${({bgcolor}) => (bgcolor || !bgcolor) ? 'transparent' : '#fff'};
    box-shadow:none;
  }
  .close {
    margin-top: -30px;
  }
  // @media (max-height: 530px) {
  //   transform: none !important;
  //   margin: 30px auto;
  // }
  // @media (max-width: 766px) {
  //   width: 95%;
  // }
  @media (max-width: 576px) {
      width: 100%;
    }
}
@media (max-width: 576px) {
  max-width: 100%;
}
`;

const PopupWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
  width:100%;
  height:650px;
  .closeIcon{
    width:100%;
    display:flex;
    margin:auto;
    cursor:pointer;
    >img{
      width:22px;
      height:22px;
      margin:auto;
      margin-right:10px;
      margin-bottom:10px;
    }
  }
  .imageContainer{
    width:50%;
    display:flex;
    background: #000000;
    border-radius: 6px 0 0px 6px;
    >img{
      width:100%;
      max-height:650px;
      object-fit: contain;
    }
  }
  .commentsContainer{
    width: ${({width}) => width ? width : `50%`};
    display:block;
    flex-wrap:wrap;
    height:650px;
  }
`;

const PopupHeader = styled.div`
 height:80px;
 display:flex;
 background:white;
 padding: 15px 25px;
 width:100%;
 .profile{
  width:50px;
  height:50px;
  border-radius:50%;
  margin-right:15px;
  >img{
    width:100%;
    height:100%;
    border-radius:50%;
  }}
  .userDetails{
    display:block;
    .name{
      display:flex;
      font-family: Rubik-Medium;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color:#005C87;
    }
    .city{
      display:flex;
      font-family: Rubik;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
      color:#005C8799;
    }
  }
  .button{
    width:100px;
    height:40px;
    display:flex;
    margin-right:0px;
  }
`;

export { StyledModal, StyledBody, Main, ImgDiv, FeedDiv, CloseDiv, NoComments, CloseImgDiv, StyledPopup, PopupWrapper, PopupHeader}
